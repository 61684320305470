<template>
	<div style="width: 100%; background-color: #f8f8f8;">
		<el-row>
			<el-col class="top-col">
				<p class="top-col-img" @click="shoye"><img src="../assets/img/newlogo.png" alt=""></p>
			</el-col>
		</el-row>
		<el-row style="width: 73%; margin:20px auto; border-radius: 10px;box-shadow: 0px 0px 6px #c4c4c4;">
			<el-col>
				<el-steps :active="active" simple finish-status="success">
					<el-step title="第一步:注册账号" icon="el-icon-edit"></el-step>
					<el-step title="第二步:选择身份" icon="el-icon-s-custom"></el-step>
					<el-step title="第三步:完善资料" icon="el-icon-s-claim"></el-step>
				</el-steps>
			</el-col>
		</el-row>
		<el-row style="width: 73%; margin: 0 auto; background: white; height: 100vh;box-shadow: 0px 0px 6px #c4c4c4;" v-show="onezhuce">
			<el-col>
				<div style="height: 90px; border-bottom: 1px solid #CCCCCC;">
					<p style="width: 20%; display: block; margin: 0 auto; line-height: 100px; font-weight: bold; font-size: 20px;">注册</p>
				</div>
			</el-col>
			<el-col>
				<div style="width: 20%; margin: 30px auto;">
					<el-input v-model="zhuceone.shoujih" placeholder="请填写账号(手机号)"></el-input>
				</div>
			</el-col>
			<el-col>
				<div style="width: 20%; margin: 0 auto;">
					<huadong ref='huadong'></huadong>
				</div>
				
			</el-col>
			<el-col>
				<div style="width: 20%; margin: 30px auto 0;display: flex;">
					<el-input v-model="zhuceone.yanzengma" placeholder="验证码"></el-input>
					<el-button @click='click_yanzm'>获取验证码</el-button>
				</div>
			</el-col>
			<el-col>
				<div style="width: 6%; margin: 20px auto;">
					<el-button @click='nextone'>下一步</el-button>
				</div>
			</el-col>
		</el-row>
		
		
		
		<el-row style="width: 73%; margin: 0 auto; background: white; height: 100vh;box-shadow: 0px 0px 6px #c4c4c4;" v-show="towzhuce">
			<el-col>
				<div style="height: 90px; border-bottom: 1px solid #CCCCCC;">
					<p style="width: 20%; display: block; margin: 0 auto; line-height: 100px; font-weight: bold; font-size: 20px;">注册</p>
				</div>
			</el-col>
		</el-row>
		

	</div>
</template>
<script>
	import {
		requestSend_sms,
		requestCheck_sms,
	} from "../api/request";
	import huadong from '../components/huadong.vue'
	export default {
		data() {
			return {
				active: 0,
				onezhuce:true,
				towzhuce:false,
				zhuceone: {
					shoujih: '',
					yanzengma: '',
				},
				
			}
		},
		components: {
			huadong
		},
		//函数方法
		methods: {
			shoye() {
				this.$router.push('/')
			},
			click_yanzm(){
				if (this.zhuceone.shoujih.length <= 0) {
					this.$message({
						message: '请输入手机号',
						type: 'warning',
						offset: 100
					});
					return
				}
				// requestSend_sms(parseInt(this.zhuceone.shoujih)).then(res=>{
				// 	if(res.code==0){
				// 		this.$message({
				// 		    message:'发送成功',
				// 		    type: 'success'
				// 		});
				// 	}
				// })
			},
			nextone() {
				if (this.zhuceone.shoujih.length <= 0) {
					this.$message({
						message: '请输入手机号',
						type: 'warning',
						offset: 100
					});
					return
				}
				if(!this.$refs.huadong.isSuccess){
					this.$message({
						message: '请滑动解锁',
						type: 'warning',
						offset: 100
					});
					return
				}
				if (this.zhuceone.yanzengma.length <= 0) {
					this.$message({
						message: '请输入验证码',
						type: 'warning',
						offset: 100
					});
					return
				}
				console.log(this.$refs.huadong.isSuccess)
				let datas={
					mobile:this.zhuceone.shoujih,
					code:this.zhuceone.yanzengma
				}
				// requestCheck_sms(datas).then(res=>{
				// 	console.log(res)
				// })
				
				if (this.active++ > 2) this.active = 0;
				
				this.onezhuce=false;
				this.towzhuce=true;
				
				
				
			}
		},
		mounted() {
			console.log(this.$parent);
			// this.$parent.$refs.Rightfloatingframe.$el.style.display = 'none';
			this.$parent.$refs.headerTop.$el.style.display = 'none';
			// this.$parent.$refs.login.$el.style.display = 'none';
			// this.$parent.$refs.head.$el.style.display = 'none';
			// this.$parent.$refs.navBar.$el.style.display = 'none';
		},
		destroyed() {
			// this.$parent.$refs.Rightfloatingframe.$el.style.display = 'block';
			this.$parent.$refs.headerTop.$el.style.display = 'block';
			// this.$parent.$refs.login.$el.style.display = 'block';
			// this.$parent.$refs.navBar.$el.style.display = 'block';
		}
	};
</script>
<style scoped>
	@import "../style/common.css";

	.top-col {
		height: 87px;
		box-shadow: 0 7px 7px -7px #5E5E5E;
		margin-bottom: 7px;
	}

	.top-col-img {
		cursor: pointer;
		margin-top: 14px;
		margin-left: 270px;
	}

	.main_row {
		height: 67.3vh;
		background-color: #f8f8f8;
		background-image: url("../assets/img/NewLoginBack.jpg");
		background-repeat: no-repeat;
		background-position: 430px bottom;
		overflow: hidden;
	}

	.login_tips {
		margin-top: 48px;
		width: 582px;
		height: 516px;
		background-color: white;
		box-shadow: 0px 0px 6px #7e7e7e;
		border-radius: 5px;
	}

	.login_tips_top {
		height: 63px;
		line-height: 63px;
		text-align: center;
		border-bottom: 1px solid #dddddd;
		color: #4f4f4f;
	}

	.login_inp {
		width: 319px;
		height: 44px;

		display: block;
		margin: 0 auto;
	}

	.login_btn {
		width: 319px;
		height: 44px;
		border-radius: 0 !important;
		background-color: #b7482c;
		display: block;
		margin: 37px auto 8px;
	}

	>>>.el-steps--simple {
		background: white;

	}
</style>
